<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Email Confirmation
          </p>
          <p class="mb-2">
            Enter your Email Address
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="Email Address"
              placeholder=""
              hide-details
              class="mb-4"
            ></v-text-field>
            <v-text-field
              v-model="verifyCode"
              outlined
              label="Verification Code"
              placeholder=""
              hide-details
              class="mb-4"
            >
            </v-text-field>
            <v-btn
              block
              color="primary"
              @click="submitRequest()"
            >
              Confirm Email
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{name:'auth-login'}"
            class="d-flex align-center text-sm"
          >
            <v-icon
              size="24"
              color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back to login</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import store from '@/store'
import { mdiChevronLeft } from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import authenticationService from '@/services/AuthenticationService'
import useAuthenticationService from '@/store/useAuthenticationService'
import { useRouter } from '@core/utils'

export default {
  data() {
    console.log('reached data')

    return {
      verifyCode: this.$route.params.token,
      email: this.$route.params.email,
    }
  },
  setup() {
    const AUTH_APP = 'app-auth'

    if (!store.hasModule(AUTH_APP)) {
      store.registerModule(AUTH_APP, authenticationService)
    }

    onUnmounted(() => {
      if (store.hasModule(AUTH_APP)) store.unregisterModule(AUTH_APP)
    })

    const { confirmEmail, errorMessages } = useAuthenticationService()

    const { router } = useRouter()

    const RedirectToLogin = () => {
      try {
        router.push('/')
      } catch (exception) {
        console.log(`Redirection Failed due to ${exception}`)
      }
    }

    return {
      RedirectToLogin,

      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      confirmEmail,
      errorMessages,

      icons: {
        mdiChevronLeft,
      },
    }
  },
  methods: {
    async submitRequest() {
      const EntryDTO = {
        Email: this.email,
        Password: this.verifyCode,
        SiteId: '00000000-0000-0000-0000-000000000000',
      }

      await this.confirmEmail(EntryDTO)
      this.RedirectToLogin()
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
